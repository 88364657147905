<template>
  <div class="app-active-container">
    <div class="header">
      <el-button type="primary" @click="handleCreate">
        添加活动页
      </el-button>
    </div>

    <el-table :data="list" border stripe row-key="activity_id">
      <el-table-column label="ID" prop="activity_id" align="center"> </el-table-column>
      <el-table-column label="标题" prop="title" align="center"> </el-table-column>
      <el-table-column label="状态" prop="status" align="center">
        <template slot-scope="scope">
          <el-tag :type="scope.row.status == 'enable' ? 'success' : 'warning'">{{
            scope.row.status | formatStatus
          }}</el-tag>
        </template>
      </el-table-column>
      <el-table-column label="页面URL" prop="url" align="center">
        <template slot-scope="scope">
          <CopyText :text="scope.row.url" />
        </template>
      </el-table-column>
      <el-table-column label="创建时间" prop="created_at" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.created_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="更新时间" prop="updated_at" align="center">
        <template slot-scope="scope">
          <div>{{ scope.row.updated_at | formatDate }}</div>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="250" align="center">
        <template slot-scope="scope">
          <el-button
            size="mini"
            type="primary"
            @click="handleEdit(scope.row)"
            v-if="scope.row.status == 'disable'"
          >
            编辑
          </el-button>
          <el-button
            v-if="scope.row.status == 'enable'"
            size="mini"
            type="warning"
            @click="handleOff(scope.row)"
          >
            下架
          </el-button>
          <el-button
            v-if="scope.row.status == 'disable'"
            size="mini"
            type="success"
            @click="handleOn(scope.row)"
          >
            上架
          </el-button>
          <el-button
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
            v-if="scope.row.status == 'disable'"
          >
            删除
          </el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import dayjs from "dayjs";
import CopyText from "@/components/CopyText.vue";
import { getActiveList, editActiveStatus } from "@/api/active";

export default {
  data() {
    return {
      postForm: {},
      isEdit: false,
      showCreateDialog: false,
      list: [],
      total: 0,
      page: 1,
      pageSize: 20,
    };
  },
  components: {
    CopyText,
  },
  filters: {
    formatDate: function(value) {
      return dayjs(value * 1000).format("YYYY-MM-DD HH:mm:ss");
    },
    formatStatus: function(value) {
      const StatusMap = {
        enable: "上架",
        disable: "下架",
      };
      return StatusMap[value];
    },
  },
  mounted() {
    this.fetchData();
  },
  computed: {},
  methods: {
    async fetchData() {
      const params = {
        page: this.page,
        page_size: this.pageSize,
      };
      try {
        const { data } = await getActiveList(params);
        const { total = 0, items = [] } = data?.data;
        this.total = total;
        this.list = [...items];
      } catch (error) {
        console.log(error);
      }
    },
    handleCreate() {
      window.open(location.origin + `/app/active/create`);
      // this.$router.push(`/app/active/create`);
    },
    handleEdit(data) {
      window.open(location.origin + `/app/active/edit/${data.activity_id}`);
      // this.$router.push(`/app/active/edit/${data.activity_id}`);
    },
    async changeStatus(params) {
      try {
        const { data } = await editActiveStatus(params);
        this.$message.success("编辑成功");
        this.fetchData();
      } catch (error) {
        console.log(error);
      }
    },
    handleOn(data) {
      console.log("上架", data.activity_id);
      const params = {
        activity_id: data.activity_id,
        status: "enable",
      };
      this.changeStatus(params);
    },
    handleOff(data) {
      console.log("下架", data.activity_id);
      const params = {
        activity_id: data.activity_id,
        status: "disable",
      };
      this.changeStatus(params);
    },
    handleDelete(data) {
      console.log("删除", data.activity_id);
      const params = {
        activity_id: data.activity_id,
        status: "delete",
      };
      this.changeStatus(params);
    },
  },
};
</script>

<style lang="less" scoped>
.app-active-container {
  margin: 20px;
  .header {
    margin-bottom: 20px;
  }
}
</style>
